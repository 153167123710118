import React from "react"
import SEO from '../components/seo';
import Layout from '../containers/layout/layout'
import Banner from '../containers/company/banner'
import Angouleme from '../containers/company/angouleme'
import VideoSection from '../containers/home-particle/video'



export default () => (
    <Layout>
        <SEO title="O Pure Création développement de site internet à Cognac / Angoulême en Charentes"/>
        <Banner/>
        <Angouleme/>
           <VideoSection/>
    </Layout>
)
