import React from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import {AngoulemeSectionWrap, SectionTitle, AboutContent} from './angouleme.stc'
 
const Angouleme = ({HeadingStyle, Heading2Style, paragraph, TextStyle, ProjectTypeHeading, ProjectTypeText}) => {
    const angoulemeQueryData = useStaticQuery(graphql `
        query AngoulemeDataQuery {
            angoulemedataJson(id: {eq: "angouleme-intro-content"}) {
                id
                title
                desc
                content
            }
        }      
    `);
    const {title, desc, content} = angoulemeQueryData.angoulemedataJson;
 
    return (
        <AngoulemeSectionWrap>
            <div className="row">
                {(title || desc) && (
                    <div className="col-1 offset-1">
                        <SectionTitle>
                            {title && <Heading {...HeadingStyle}>{title}</Heading>}
                            {desc && <Text {...TextStyle}>{desc}</Text>}                        
                        </SectionTitle>
                    </div>
                )}
                {content && (
                    <div className="col-2 offset-1">
                        <AboutContent>
                             {content.map((textData, i) => <Text key={`story-text-${i}`} {...paragraph}>{textData}</Text>)}
                        </AboutContent>
                    </div>
                )}
            </div>
        </AngoulemeSectionWrap>
    )
}

Angouleme.propTypes = {
    HeadingStyle: PropTypes.object,
    TextStyle: PropTypes.object
}

Angouleme.defaultProps = {
    HeadingStyle: {
        fontSize: '40px',
        texttransform: 'capitalize',
        lineHeight: '55px',
        color: 'primary',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '24px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '25px',
                lineHeight: '35px'
            }
        }
    },
    TextStyle: {
        lineHeight: '26px'
    },
    ProjectTypeHeading: {
        as: 'h6',
        color: 'primary',
        fontSize: '12px',
        fontweight: 700,
        letterspacing: '2px',
        mb: '12px'
    },
     ProjectTypeHeading2: {
        as: 'h6',
        color: '#ff005a',
        fontSize: '12px',
        fontweight: 700,
        letterspacing: '2px',
        mb: '12px'
    },
    ProjectTypeText: {
        m: 0,
        fontSize: '12px',
        color: '#000000',
        letterspacing: '1px'
    }
}

export default Angouleme;